import {analyticsConstants} from '../constants/analyticsConstants';

export const getPredefinedReportsList = (publisherId) => ({
    type: analyticsConstants.PREDEFINED_REPORTS_LIST_REQUEST,
    payload: {publisherId}
});
export const getPredefinedReportsListSuccess = (data) => ({
    type: analyticsConstants.PREDEFINED_REPORTS_LIST_SUCCESS,
    payload: {data}
});
export const getPredefinedReportsListFailed = (error) => ({
    type: analyticsConstants.PREDEFINED_REPORTS_LIST_FAILURE,
    payload: {error}
});

export const getPredefinedReportData = (publisherId, reportId, exportType, period, isDownloading, userEmail, reportQuery, dateGranularity) => ({
    type: analyticsConstants.PREDEFINED_REPORT_DATA_REQUEST,
    payload: {publisherId, reportId, exportType, period, isDownloading, userEmail, reportQuery, dateGranularity}
});
export const getPredefinedReportDataSuccess = (data, exportType) => ({
    type: analyticsConstants.PREDEFINED_REPORT_DATA_SUCCESS,
    payload: {data, exportType}
});
export const getPredefinedReportDataFailed = (error) => ({
    type: analyticsConstants.PREDEFINED_REPORT_DATA_FAILURE,
    payload: {error}
});
export const clearPredefinedReportData = () => ({
    type: analyticsConstants.CLEAR_PREDEFINED_REPORT_DATA,
    payload: {}
});

export const getPublisherReportsConfig = (publisherId) => ({
    type: analyticsConstants.PUBLISHER_REPORTS_CONFIG_REQUEST,
    payload: {publisherId}
});
export const getPublisherReportsConfigSuccess = (data) => ({
    type: analyticsConstants.PUBLISHER_REPORTS_CONFIG_SUCCESS,
    payload: {data}
});
export const getPublisherReportsConfigFailed = (error) => ({
    type: analyticsConstants.PUBLISHER_REPORTS_CONFIG_FAILURE,
    payload: {error}
});

export const getReportPreview = (publisherId, reportId, period, dateGranularity) => ({
    type: analyticsConstants.RAW_REPORT_DATA_REQUEST,
    payload: {publisherId, reportId, period, dateGranularity}
});
export const getReportPreviewSuccess = (data) => ({
    type: analyticsConstants.RAW_REPORT_DATA_SUCCESS,
    payload: {data}
});
export const getReportPreviewFailed = (error) => ({
    type: analyticsConstants.RAW_REPORT_DATA_FAILURE,
    payload: {error}
});

export const changePage = (nextPage) => ({
    type: analyticsConstants.CHANGE_PAGE,
    payload: {nextPage}
});

export const setLastPage = (lastPage) => ({
    type: analyticsConstants.SET_LAST_PAGE,
    payload: {lastPage}
});

export const setZoomFactor = (zoomFactor) => ({
    type: analyticsConstants.SET_ZOOM_FACTOR,
    payload: {zoomFactor}
});

export const closeReportPreview = () => ({
    type: analyticsConstants.CLOSE_WEB_PREVIEW
});

export const setPredefinedReportPeriod = period => ({
    type: analyticsConstants.SET_PREDEFINED_REPORT_PERIOD,
    payload: period
});

export const setReportScheduling = (data) => ({
    type: analyticsConstants.SET_REPORT_SCHEDULING_REQUEST,
    payload: {data}
});
export const setReportSchedulingSuccess = (data) => ({
    type: analyticsConstants.SET_REPORT_SCHEDULING_SUCCESS,
    payload: {data}
});
export const setReportSchedulingFailed = (error) => ({
    type: analyticsConstants.SET_REPORT_SCHEDULING_FAILED,
    payload: {error}
});

export const updateReportQuery = (publisherId, reportId, reportQuery) => ({
    type: analyticsConstants.UPDATE_REPORT_QUERY_REQUEST,
    payload: {publisherId, reportId, reportQuery}
});
export const updateReportQuerySuccess = (updatedReportQuery) => ({
    type: analyticsConstants.UPDATE_REPORT_QUERY_SUCCESS,
    payload: {updatedReportQuery}
});
export const updateReportQueryFailed = (error) => ({
    type: analyticsConstants.UPDATE_REPORT_QUERY_FAILED,
    payload: {error}
});

export const changeReportQuery = (reportId, query) => ({
    type: analyticsConstants.CHANGE_REPORT_QUERY,
    payload: {reportId, query}
});

export const deleteChangedQuery = (reportId) => ({
    type: analyticsConstants.DELETE_CHANGED_QUERY,
    payload: {reportId}
})