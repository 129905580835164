import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";

import {isValidDate, formatToEstDate} from './imports';

const CLASS_NAMES = {
    REPORT_VIEWER_WRAPPER: 'report-viewer-wrapper',
    MAIN_HEADER_ROW: 'main-header-row',
    TABLE_HEADER_CELL: 'table-header-cell',
    REPORT_VIEWER_FOOTER: 'report-viewer-footer',
};

const totalValueMapper = (tableHeaders, val, i) => {
    if (i === 0) {
        return 'Total:';
    } else if (typeof val == 'number') {
        return valueMapper(tableHeaders, i, val, true);
    }
    return '';
}

const valueMapper = (tableHeaders, i, el, isTotalVal) => {
    const decimalFormat = /(\d)(?=(\d{3})+\.)/g;
    const thousandFormat = /\B(?=(\d{3})+(?!\d))/g;
    let result = el;
    const theader = tableHeaders;
    const decimalFormatCols = ['eCPM', 'Revenue*', 'Revenue', 'Revenue (Cost)'];
    decimalFormatCols.map(item => {
        const foundIndex = theader.indexOf(item);
        if(foundIndex === i) {
            result = '$' + Number(el).toFixed(2).replace(decimalFormat, '$1,');
        }
        return item;
    });
    const thousandFormatCols = ['Payable Units', 'Billable Units', 'Requests', 'Rendered Impressions', 'Booked Units',
    'Impressions', 'Clicks', 'Publisher Bid Requests', 'Publisher Bid Responses', 'Won Bids', 'Bid Requests',
    'Bid Responses'];
    thousandFormatCols.map(item => {
        const foundIndex = theader.indexOf(item);
        if(foundIndex === i) {
            result = el && el.toString().replace(thousandFormat, ",");
        }
        return item;
    });
    const percentageFormatCols = ['Undertone Bid Rate In %', 'Won Bid Percentage', 'CTR', 'Win Rate', 'Bid Rate', 'Fill Rate'];
    percentageFormatCols.map(item => {
        const foundIndex = theader.indexOf(item);
        if (el === 'N/A') return item;
        if(foundIndex === i) {
            const nr = Number(el) * 100;
            result = nr.toFixed(2) + '%';
        }
        return item;
    });
    const dateFormatCols = ['Date', 'Start', 'End'];
    dateFormatCols.map(item => {
        const foundIndex = theader.indexOf(item);
        if(foundIndex === i && isValidDate(result) && isNaN(Number(result))) {
            result = formatToEstDate(result);
        }
        return item;
    });
    return isTotalVal ? result : <td key={i} >{result}</td> 
};

const ReportViewer = (props) => {
    const {reportPreview, zoomFactor, reportViewerRef} = props;
    const reportContentKeys = Object.keys(reportPreview.content[0]);
    const reportHeaderValues = Object.values(reportPreview.tableHeader);
    const nonAlphanumericAndWhitespace = /[\W_]/g;
    const newTotal = [...reportPreview.total];
    const tableHeaders = reportHeaderValues.filter((el, i) => {
        const tableHeaderFound = reportContentKeys.find(column =>
            column.replace(nonAlphanumericAndWhitespace, '').toLowerCase() === el.replace(nonAlphanumericAndWhitespace, '').toLowerCase());
        !tableHeaderFound && newTotal.splice(i, 1);
        return tableHeaderFound;
    });
    return (
        <table style={{transform: `scale(${zoomFactor})`, 'transformOrigin': '0 0'}} ref={reportViewerRef}>
            <tbody>
                {Object.values(reportPreview.reportHeader).map((value, index) => {
                    return (
                        <tr key={index}>
                            <td colSpan={Object.values(reportPreview.tableHeader).length}
                                className={CLASS_NAMES.MAIN_HEADER_ROW}
                            >
                                {value}
                            </td>
                        </tr>
                    )
                })}
                <tr className={CLASS_NAMES.TABLE_HEADER_CELL}>
                    {tableHeaders.map((el, i) => <td key={i} >{el}</td>)}
                </tr>
                {reportPreview.content.map((obj, index) => {
                    return (
                        <tr key={index}>
                            {Object.values(obj).map((el, i) => valueMapper(tableHeaders, i, el))}
                        </tr>
                    )
                })}
                <tr className={CLASS_NAMES.REPORT_VIEWER_FOOTER}>
                    {newTotal.map((totalVal, totalInd) =>
                        <td key={totalInd}>
                            {totalValueMapper(tableHeaders, totalVal, totalInd)}
                        </td>
                    )}
                </tr>
            </tbody>
        </table>
    );
};

ReportViewer.propTypes = {
    reportViewerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any})
    ]),
    reportPreview: PropTypes.object,
    rawDataPaginated: PropTypes.array,
    zoomFactor: PropTypes.number
};

const mapStateToProps = ({reportPreview}) => ({
    zoomFactor: reportPreview.zoomFactor
});

export default connect(mapStateToProps)(ReportViewer);
