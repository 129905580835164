import memoize from 'lodash/memoize';
import {NEW_REPORTS} from './imports';

const getActiveReportsList = memoize((data, flags) => {
    const newReports = [];
    const predefinedReports = [];

    const placementReport = data.find(row => row.id === NEW_REPORTS.PLACEMENT_REPORT);
    const deliveryReport = data.find(row => row.id === NEW_REPORTS.DELIVERY_REPORT);
    const deliveryAndDomainReport = data.find(row => row.id === NEW_REPORTS.DELIVERY_AND_DOMAIN_REPORT);
    const deliveryAndDeviceReport = data.find(row => row.id === NEW_REPORTS.DELIVERY_AND_DEVICE_REPORT);
    const deliveryDomainAndDeviceReport = data.find(row => row.id === NEW_REPORTS.DELIVERY_DOMAIN_AND_DEVICE_REPORT);

    // flags need to be changed!!
    if (placementReport && flags.isHbPerformanceReportEnabled) {
        newReports.push(placementReport);
    }
    if (deliveryReport && flags.isInvoiceReportEnabled) {
        newReports.push(deliveryReport);
    }
    if (deliveryAndDomainReport && flags.isRevenueReportEnabled) {
        newReports.push(deliveryAndDomainReport);
    }
    if (deliveryAndDeviceReport && flags.isPlacementReportEnabled) {
        newReports.push(deliveryAndDeviceReport);
    }
    if (deliveryDomainAndDeviceReport && flags.isPlacementReportEnabled) {
        newReports.push(deliveryDomainAndDeviceReport);
    }

    return {
        new: newReports,
        predefined: predefinedReports
    };
});

export default getActiveReportsList;
