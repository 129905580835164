export const PERIOD_TYPES = {
    TODAY: 'today',
    YESTERDAY: 'yesterday',
    LAST_7_DAYS: 'last-7-days',
    LAST_30_DAYS: 'last-30-days',
    THIS_MONTH: 'this-month',
    LAST_MONTH: 'last-month',
    LAST_WEEK: 'last-week',
    CURRENT_WEEK: 'current-week',
    CURRENT_MONTH: 'current-month',
    CUSTOM: 'custom'
};

// export const NEW_REPORTS = {
//     HB_PERFORMANCE: 'HB_PERFORMANCE',
//     INVOICE: 'INVOICE',
//     REVENUE: 'REVENUE',
//     PLACEMENT: 'PLACEMENT',
//     DOMAIN_REVENUE: 'DOMAIN_REVENUE',
//     PURCHASE_ORDER: 'PURCHASE_ORDER',
//     PURCHASE_ORDER_DAILY_TRENDS: 'PURCHASE_ORDER_DAILY_TRENDS'
// };

export const NEW_REPORTS = {
    PLACEMENT_REPORT: 'placement-report',
    DELIVERY_REPORT: 'delivery-report',
    DELIVERY_AND_DOMAIN_REPORT: 'delivery-domain-report',
    DELIVERY_AND_DEVICE_REPORT: 'delivery-device-report',
    DELIVERY_DOMAIN_AND_DEVICE_REPORT: 'delivery-domain-device-report',
};